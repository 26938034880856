import { NavLink, Outlet } from "react-router-dom";
import PathLinks from "../components/PathLinks";

export default function RootLayout() {
  return (
    <div className="root-layout">
      <header>
        <nav>
          <h1>KOALALOOKA</h1>
          <NavLink to="/">Home</NavLink>
          <NavLink to="/bookclub">BookClub</NavLink>
          <NavLink to="/resources">Resources</NavLink>
          <NavLink to="/news">News</NavLink>
          <NavLink to="/login">Login</NavLink>
        </nav>
      </header>
      <PathLinks />
      <main>
        <Outlet />
      </main>
    </div>
  );
}

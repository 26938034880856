import { Link, useLocation } from "react-router-dom";

export default function PathLinks() {
  const location = useLocation();
  console.log(location);
  let currentLink = "";
  const links = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb) => {
      currentLink += crumb;
      return (
        <div className="link" key={crumb}>
          <Link to={currentLink}>{crumb}</Link>
        </div>
      );
    });

  return <div className="pathLink">{links}</div>;
}

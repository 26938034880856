import React from "react";

export default function CriketMagzine() {
  return (
    <div className="resources-layout">
      <h2>Award-winning Magazines for Children</h2>
      <p>
        For over 40 years, Cricket Media's award-winning, ad-free magazines have
        challenged children's minds and inspired a sense of wonder and curiosity
        about the world around them. We are committed to building children's
        confidence, creativity, and curiosity through high-quality
        age-appropriate content.
      </p>
    </div>
  );
}

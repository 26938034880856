export const db = {
  news: [
    {
      id: "1",
      title: "2022 Team 1 Groupbuy",
      description:
        "2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing.2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing. 2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing.",
      endTime: "2022-2-15",
    },
    {
      id: "2",
      title: "2022 Team 2 Groupbuy",
      description:
        "2023 Term 2 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing.2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing. 2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing.",
      endTime: "2022-5-15",
    },
    {
      id: "3",
      title: "2022 Team 3 Groupbuy",
      description:
        "2023 Term 3 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing.2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing. 2023 Term 1 Groupbuy for Singapore Math Series Targeting Math and Understanding Math is now ongoing.",
      endTime: "2022-8-15",
    },
  ],
};

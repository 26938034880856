import React from "react";
import { Outlet } from "react-router-dom";

export default function NewsLayout() {
  return (
    <div className="resources-layout">
      <h2>News </h2>
      <Outlet></Outlet>
    </div>
  );
}

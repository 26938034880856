import React from "react";

export default function Raz() {
  return (
    <div className="resources-layout">
      <h2>Raz</h2>
      <p>
        Learning A-Z is a leading provider of online educational resources for
        teachers and students, and Raz-Plus is one of its flagship products.
        Raz-Plus is a comprehensive blended learning platform that provides a
        wide range of resources and tools for teachers to help improve their
        students' reading skills. The platform includes a vast collection of
        interactive e-books, printable resources, and lesson plans, and it is
        designed to be used with students at different levels of reading
        proficiency. The platform also provides features such as assessment
        tools, data tracking, and reporting to help teachers monitor student
        progress and tailor their instruction to meet their students' needs.
        With its user-friendly interface and robust features, Raz-Plus has
        become a trusted resource for teachers and students across the United
        States and around the world.
      </p>
    </div>
  );
}

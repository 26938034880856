import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <div className="notfound">
      <h2>Page Not found</h2>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolore
        similique sint facere quos pariatur odit. Modi tempora doloremque
        dolorum, eos, officia maiores, fuga est ratione expedita sunt rerum ex
        quod.
      </p>
      Go to the <Link to="/">Homepage</Link>
    </div>
  );
}

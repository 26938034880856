import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

// layout import
import RootLayout from "./layout/RootLayout";

import Home from "./pages/Home";
import About from "./pages/About";
import BookClub from "./pages/BookClub";
import ResourcesLayout from "./layout/ResourcesLayout";
import News, { newsLoader } from "./pages/News";
import Raz from "./pages/Raz";
import CriketMagzine from "./pages/CriketMagzine";
import Login from "./pages/Login";
import NotFound from "./pages/NotFound";
import NewDetail, { newDetailsLoader } from "./pages/NewDetail";
import NewsLayout from "./layout/NewsLayout";
import NewsError from "./pages/NewsError";

const route = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="bookclub" element={<BookClub />} />
      <Route path="resources" element={<ResourcesLayout />}>
        <Route path="raz" element={<Raz />} />
        <Route path="CricketMagzine" element={<CriketMagzine />} />
      </Route>
      <Route path="news" element={<NewsLayout />}>
        <Route
          index
          element={<News />}
          loader={newsLoader}
          errorElement={<NewsError />}
        />
        <Route
          path=":id"
          element={<NewDetail />}
          loader={newDetailsLoader}
          errorElement={<NewsError />}
        />
      </Route>
      <Route path="login" element={<Login />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

function App() {
  return <RouterProvider router={route} />;
}

export default App;
